import { createI18n } from 'vue-i18n'
import  addressTranslation  from 'aveine-address/src/translations/address';
import  ambassadorTranslation  from 'aveine-ambassador/src/translations/ambassador';
import  toolboxTranslation  from 'aveine-toolbox/src/translations/toolbox';
import  invoiceTranslation  from 'aveine-invoice/src/translations/invoice';
import  saleTranslation from 'aveine-sale/src/translations/sale';
import aveineSpaceTranslation from '../translations/aveine-space';


const messages = {
  'EN': { 
    "aveine-address": addressTranslation.en, 
    "aveine-ambassador": ambassadorTranslation.en,
    "aveine-invoice": invoiceTranslation.en,
    "aveine-sale": saleTranslation.en, 
    "aveine-toolbox": toolboxTranslation.en,
    "aveine-space": aveineSpaceTranslation.en
},
  'FR': {
    "aveine-address": addressTranslation.fr, 
    "aveine-ambassador": ambassadorTranslation.fr,
    "aveine-invoice": invoiceTranslation.fr,
    "aveine-sale": saleTranslation.fr, 
    "aveine-toolbox": toolboxTranslation.fr,
    "aveine-space": aveineSpaceTranslation.fr
},
}
const i18n = createI18n({
    locale: 'EN',      
    messages
});

export default i18n