import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Authenticated from '@/views/Authenticated.vue'

import Pro from '@/views/authenticated/pro/Pro.vue'
import UpdateSelfAmbassador from '@/views/authenticated/pro/UpdateSelfAmbassador.vue'
import SalesRead from '@/views/authenticated/pro/Sales.vue'
import AmbassadorDashboard from '@/views/authenticated/pro/AmbassadorDashboard.vue'
import Invoice from '@/views/authenticated//pro/invoices/Invoice.vue'
import NotRegistered from '@/views/authenticated/pro/NotRegistered.vue'
import InvoiceCreate from '@/views/authenticated/pro/invoices/Create.vue'

import Login from '@/views/Login.vue'
import Registration from '@/views/Registration.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/authenticated',
    name: "Authenticated",
    component: Authenticated,
    children: [
      {
        path: 'pro',
        name: "Pro",
        component: Pro,
        children: [
          {
            path: 'ambassadors/update/:id',
            name: 'UpdateSelfAmbassador',
            component: UpdateSelfAmbassador
          },
          {
            path: 'ambassadors/read/:id',
            name: 'AmbassadorRead',
            component: UpdateSelfAmbassador
          },
          {
            path: 'sales/:id',
            name: 'AmbassadorSales',
            component: SalesRead
          },
          {
            path: 'dashboard',
            name: 'AmbassadorDashboard',
            component: AmbassadorDashboard
          },
          {
            path: 'invoices/:id',
            name: 'Invoices',
            component: Invoice
          },
          {
            path: 'invoices/add/:id',
            name: 'InvoiceCreate',
            component: InvoiceCreate
          }
        ]
      },
      {
        path: 'not-registered',
        name: 'NotRegistered',
        component: NotRegistered
      },
    ]
  },
  {
    path: '/login',
    alias: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})
export default router
