import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home py-5" }
const _hoisted_2 = { class: "d-flex flex-row page-title" }
const _hoisted_3 = {
  key: 0,
  class: "form-container"
}
const _hoisted_4 = {
  key: 1,
  class: "d-flex justify-center align-center mt-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AveineAmbassadorForm = _resolveComponent("AveineAmbassadorForm")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("aveine-space.title.page.editMyInformation")), 1),
    (_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AveineAmbassadorForm, {
            APIbaseURL: _ctx.APIbaseURL,
            ambassador: _ctx.ambassador
          }, null, 8, ["APIbaseURL", "ambassador"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_v_progress_circular, {
            indeterminate: "",
            size: 100,
            width: 10,
            color: "secondary"
          })
        ]))
  ]))
}