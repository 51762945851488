<template>
  <div v-if="$auth.ambassador && $auth.ambassador.id" class="dashboard py-5">
    <div class="d-flex flex-lg-row flex-xl-row flex-column-reverse justify-xl-center mx-3 align-center">
      <div  class="column-1 mx-2" v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']">
        <v-card class="rounded-lg promotional-banner py-16 px-6 pb-4">
            <h2 class="mb-6 text-left">
              {{$t("aveine-space.title.declareRevenue")}}
            </h2>
            <span v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']"> <p>{{$t("aveine-space.sales.unlockedMoney")}}</p>  <br> <h1>{{invoiceSummary["amount-unlocked-euro"] ? invoiceSummary["amount-unlocked-euro"] + " EUR" : invoiceSummary["amount-unlocked-dollar"] + " USD"}}</h1> </span>

            <v-btn
              class="my-5" rounded-lg
              color="primary"
              variant="flat"
              :to="{ name:'InvoiceCreate', params: {id: $auth.ambassador.id} }"
            >
              {{$t("aveine-space.title.declare")}}
            </v-btn>
        </v-card>
      </div>
      <div class="column-1 mx-2" v-else> 
        <v-card class="rounded-lg promotional-banner py-16 px-6 pb-4" v-if="$auth.ambassador">
          <h2 v-html="$t('aveine-space.title.websitePromo')" class="mb-4 mt-16 text-left" style="color: #ffffff">
            
          </h2>
          <v-btn class="ma-1 mb-5" rounded-sm href="https://aveine.com/" target="_blank">{{$t("aveine-space.title.accessWebsite")}}</v-btn>
        </v-card>
      </div>
      <div class="column-2 mx-2">
        <div class="d-flex justify-center">
          <v-card class="py-6 px-14 rounded-lg" v-if="$auth.ambassador">
            <v-avatar
              rounded
              class="ma-6"
              size="100"
            >
              <v-img src="@/assets/material-person.png"></v-img>
            </v-avatar>
            <div class="text-h6 text-center mb-1">
              {{$auth.ambassador.name}} 
            </div>
            <v-col class="discount-code d-flex flex-column align-center mt-4">
              <p class="ma-1 text-light">{{$t("aveine-space.sales.discountCode")}}</p> <h2 class="ma-1">{{$auth.ambassador.promotion_code}}</h2>
            </v-col>
          </v-card>
        </div>
      </div>
    </div>

    <div class="d-flex flex-lg-row flex-xl-row flex-column justify-xl-center mx-2 align-center">
      <div v-if="$vuetify.display.name == 'xl' || $vuetify.display.name == 'lg'" class="column-1 mx-2">
        <v-card class="d-flex pa-6 px-xl-14 px-lg-12 rounded-lg chart-container">
          <h2 class="mb-5" :to="{ name:'AmbassadorSales', params: {id: $auth.ambassador.id} }">
            <router-link style="" :to="{ name:'AmbassadorSales', params: {id: $auth.ambassador.id} }">
              {{$t("aveine-space.title.page.mySales")}} 
            </router-link>
          </h2>
          <AveineSaleCharts class="component" :dashboardActions="true" :presentationMode="true" :ambassadorId="$auth.ambassador.id" :discountCode="$auth.ambassador.promotion_code" :selectedCurrency="selectedCurrency" />
        </v-card>
      </div>

      <div class="column-2 mx-2">
        <v-card class="sale-details rounded-lg text-left align-start">
          <h2 class="mb-4 ml-6" >
            <router-link :to="{ name:'AmbassadorSales', params: {id: $auth.ambassador.id} }">{{$t("aveine-space.dashboard.ambassadorSales")}} </router-link>
          </h2>
          <AveineSaleDetails class="component" :summaryMode="true" :selectedCurrency="selectedCurrency" :monthSalesNumber="monthSalesNumber" :yearSalesNumber="yearSalesNumber" :totalSalesNumber="totalSalesNumber" :totalIncome="totalIncome" :pendingIncome="pendingIncome" :adminMode="true"/>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AveineSaleDetails  from 'aveine-sale/src/components/AveineSaleDetails.vue'
import {Order} from "aveine-toolbox/src/store/models/Order"
import AveineSaleCharts from 'aveine-sale/src/components/AveineSaleCharts.vue'
import errorHandler from 'aveine-toolbox/src/store/errorHandler'
export default defineComponent({
  name: 'AmbassadorDashboard',
  data () {
    return {
      orders: [],
			/**
			 * Represent the different currencies
			 *
			 * @type {Object[]}
			 */
			currencies: [
				{
					name: 'Euros',
					code: 'EUR',
				},
				{
					name: 'Dollars',
					code: 'USD',
				}
      ],
      /**
       * The selected currency
       * 
       * @type {String}
       */
      selectedCurrency: null as string | null,
      /**
       * Flag to know if page data has been loaded
       * 
       * @type {boolean}
       */
      dataLoaded: false as boolean,
      /**
       * Store monthly sale number by product
       * 
       * @type {Object}
       */
      monthSalesNumber: {} as any,
      /**
       * Store yearly sale number by product
       * 
       * @type {Object}
       */
      yearSalesNumber: {} as any,
      /**
       * Store total sale number by product
       * 
       * @type {Object}
       */
      totalSalesNumber: {} as any,
      /**
       * Store the pending income the current user has grossed
       * 
       * @type {Object}
       */
      pendingIncome: {} as any,
      /**
       * Store the total income the current user has grossed
       * 
       * @type {Object}
       */
      totalIncome: {} as any,
      /**
       * Store the invoicing information of the current user
       * 
       * @type {Object}
       */
      invoiceSummary: {} as any
    }
  },
  components: {
    AveineSaleDetails,
    AveineSaleCharts
  },
  async created() {
    try {
      if (this.$auth.ambassador && this.$auth.ambassador.id) {
        this.selectedCurrency = this.currencies[0].code;
        
        let begginingMonthDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('.')[0]+"Z";
        let endMonthDate =  new Date(new Date().getFullYear(), new Date().getMonth()+1, 1).toISOString().split('.')[0]+"Z";

        let fetchMonthSummary = await Order.api("GET", `orders/ambassadors/${this.$auth.ambassador.id}/short-summary?filter=created-at=ge=${begginingMonthDate};created-at=le=${endMonthDate}`)
        this.monthSalesNumber = {aveineAmount: fetchMonthSummary.meta["aveine-amount"], essentielAmount: fetchMonthSummary.meta["estl-amount"]}
        
        let begginingYearDate = new Date(new Date().getFullYear(), 1, 1).toISOString().split('.')[0]+"Z";
        let endYearDate =  new Date(new Date().getFullYear(), 12, 31).toISOString().split('.')[0]+"Z";

        let fetchYearSummary = await Order.api("GET", `orders/ambassadors/${this.$auth.ambassador.id}/short-summary?filter=created-at=ge=${begginingYearDate};created-at=le=${endYearDate}`)
        this.yearSalesNumber = {aveineAmount: fetchYearSummary.meta["aveine-amount"], essentielAmount: fetchYearSummary.meta["estl-amount"]}

        let fetchTotalSummary = await Order.api("GET", `orders/ambassadors/${this.$auth.ambassador.id}/summary`)
        this.invoiceSummary = fetchTotalSummary.meta;

        this.totalSalesNumber = {aveineAmount: this.invoiceSummary["total-aveine-amount"], essentielAmount: this.invoiceSummary["total-estl-amount"]}
        this.totalIncome = { 
          USD: parseInt(this.invoiceSummary["amount-paid-dollar"]),
          EUR: parseInt(this.invoiceSummary["amount-paid-euro"])
        }
        this.pendingIncome = {
          USD: parseInt(this.invoiceSummary["amount-locked-dollar"]) + parseInt(this.invoiceSummary["amount-unlocked-dollar"]) + parseInt(this.invoiceSummary["amount-pending-dollar"]),
          EUR: parseInt(this.invoiceSummary["amount-locked-euro"]) + parseInt(this.invoiceSummary["amount-unlocked-euro"]) + parseInt(this.invoiceSummary["amount-pending-euro"]),
        }
      } else {
        this.$router.replace("/registration")
      }

      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  div.dashboard {
    .column-1 {
      display: flex;
      justify-content: center;
      width: 50vw;
    }
    .column-2 {
      display: flex;
      justify-content: center;
      width: 20vw;
    }
    .v-card {
      background-color: rgb(var(--v-theme-beige));
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 3em;
      height: 400px;
      .v-avatar {
        border-radius: 50%;
        padding: 20px;
        background-color: white;
      }
      div.discount-code {
        margin-bottom: 2vh;
        h2 {
          background-color: rgb(var(--v-theme-primary));
          font-family: 'Montserrat', sans-serif !important;
          font-weight: bold;
          padding: 5px 1.8em;
          border-radius: 10px;
          color: white;
          font-size: 1.3em;
        }
      }
      h2 {
        font-family: 'ivypresto-display', sans-serif;
        font-weight: normal;
        text-align: left;
        font-size: 30px;
        line-height: 1.3em;
        a {
          text-decoration: none;
        }
      }
    }
    .promotional-banner {
      background-image: url('../../../assets/promo_banner.png');
      background-size: cover;
      width: 100%;
      height: 340px;
      align-items: start !important;
      align-self: start !important;
      h1 {
        font-family: 'ivypresto-display', sans-serif;
        font-weight: normal;
        text-align: left;
        font-size: 52px;
        line-height: 1.3em;
      }
    }
    .chart-container {
      background-color: white;
      text-align: start;
      justify-content: start;
      align-items: start;
      h2 a {
        color: rgb(var(--v-theme-primary));
      }
    }
    .sale-details {
        background-color: rgb(var(--v-theme-primary)) !important;
        border-radius: 0 20px 20px 20px;
        color: white !important;
        min-width: 350px;
        a {
          text-decoration: none;
          color: white;
        }
        hr {
          color: white !important;
          opacity: 100 !important;
        }
    }
    @media (min-width: 1280px), (max-width: 1600px) {
      .promotional-banner {
          background-position: center;
      }
    }
    @media (max-width: 700px){
      .column-1 {
        width: 98vw;
      }
      .column-2 {
        width: 98vw;
      }
      .v-card {
        margin: 10px;
      }
    }
  }


</style>
