<template>
  <v-app>
    <v-app-bar
      elevation="10"
      v-if="!$auth.loading && $auth.isAuthenticated && $route.name != 'Registration' && $vuetify.display.name != 'xl' && $vuetify.display.name != 'lg'"
    >
      <div class="d-flex align-center toolbar-left">
        <div class="d-flex align-center menu-button">
          <v-btn
            icon
            @click.stop="toggleMenu = !toggleMenu"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </div>

      <v-spacer ></v-spacer>

      <div>
        <router-link to="/authenticated/dashboard">
          <v-img
            alt="Aveine"
            class="shrink mt-1"
            cover
            min-width="135"
            src="@/assets/logo-aveine.png"
            width="100"
          />
        </router-link>
      </div>

      <v-spacer ></v-spacer>
      <div class="d-flex align-center toolbar-left">
        <div class="d-flex align-center menu-button">
          <v-btn
            icon
            white
            disabled
          >
          </v-btn>
        </div>
      </div>

    </v-app-bar>
    <v-main>
      <v-dialog
        v-if="tosToSign && tosToSign.id"
        v-model="tosToSign"
        transition="dialog-bottom-transition"
        max-width="1200"
        class="mx-0"
      >
        <template>
          <v-card>
            <v-toolbar
              class="text-h6 px-6"
              color="primary"
            >{{ $t("aveine-space.title.newTosAvailable")}}
              <v-spacer>
              </v-spacer>   
              <AveineButtonLanguage/>              
            </v-toolbar>
            <v-card-text class="px-xl-16 px-lg-14 px-1">
              <div>
                <v-list-item class="d-flex justify-center">
                  <v-select
                        v-model="$i18n.locale"
                        :items="['FR', 'EN']"
                        single-line
                  >
                  </v-select>
                </v-list-item>
                <div class="contract ma-xl-6 ma-lg-4">
                  <iframe id="contract-iframe" :src="tosToSign.name + '#page=1&zoom=80'"></iframe>
                </div>
                <v-checkbox v-model="agreedToTos" :label="$t('aveine-space.onBoarding.agreeTos')"/>
              </div>            
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn v-if="agreedToTos" color="primary" @click="signTos(tosToSign)" >
                {{$t("aveine-space.title.submit")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

        
      <v-navigation-drawer
        v-if="!$auth.loading && $auth.isAuthenticated && $route.name != 'Registration'"
        class="menu-drawer d-flex align-space-between"
        color="primary"
        v-model="toggleMenu"
        absolute
        :elevation="1"
      >
        <div>
          <div v-if="$vuetify.display.name == 'xl' || $vuetify.display.name == 'lg'" class="d-flex align-center toolbar-left">
            <div class="d-flex align-center justify-center">
              <router-link to="/authenticated/dashboard">
                <v-img
                  alt="Aveine"
                  class="shrink ma-6 mt-7"
                  cover
                  min-width="180"
                  src="@/assets/logo-aveine-white.png"
                  width="100"
                />
              </router-link>
            </div>

          </div>
          <v-list density="compact">
            <v-list-item v-if="$auth.ambassador && ambassadorMenu" class="nav-links ml-6 mt-4">
              <router-link :to="{ name: 'AmbassadorDashboard'}"><v-list-item-title><v-icon color="white">mdi-home</v-icon>{{$t("aveine-space.title.menu.ambassadorDashboard")}}</v-list-item-title></router-link>
            </v-list-item>

            <v-list-item v-if="$auth.ambassador && ambassadorMenu" class="nav-links ml-6 mt-4">
              <router-link :to="{ name: 'AmbassadorSales', params: { id: $auth.ambassador.id } }"><v-list-item-title><v-icon color="white">mdi-currency-usd</v-icon>{{$t("aveine-space.title.menu.mySales")}}</v-list-item-title></router-link>
            </v-list-item>
            
            <v-list-item v-if="$auth.ambassador && ambassadorMenu" class="nav-links ml-6 mt-4">
              <router-link :to="{ name: 'Invoices', params: { id: $auth.ambassador.id } }"><v-list-item-title><v-icon color="white">mdi-file</v-icon>{{$t("aveine-space.title.menu.invoices")}}</v-list-item-title></router-link>
            </v-list-item>
          </v-list>
        </div>
        <v-list density="compact">
          <!-- <v-list-item>
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="1200"
              >
                <template v-slot:activator="{ props }">
                  <v-btn class="d-flex align-center mx-1 mr-4" variant="text" v-bind="props" color="secondary">{{$t('aveine-space.title.menu.contactUs')}}</v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      class="text-h6 px-6"
                      color="primary"
                      dark
                    >{{ $t("aveine-space.title.menu.contactUs") }}                      
                      <v-spacer></v-spacer>
                      <v-btn icon @click="dialog.value = false" >
                        <v-icon color="white">mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text class="d-flex justify-center align-center pa-16">
                      <h3 class="text-center">{{$t("aveine-space.title.contactMessage")}} <a href="mailto:ambassadeurs@aveine.paris">ambassadeurs@aveine.paris</a></h3>
                    </v-card-text>
                  </v-card>
                </template>
              </v-dialog>
          </v-list-item> -->
          
          <v-list-item class="text-center d-flex justify-center">
            <AveineLogin :key="$auth.isLoading" />
          </v-list-item>

          <v-list-item class="d-flex justify-center">
            <v-select
                  v-model="$i18n.locale"
                  :items="['FR', 'EN']"
                  single-line
            >
            </v-select>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-container v-if="loadRoute" fluid class="app-container">
        <router-view :key="$route.fullPath"/>
      </v-container> 
    </v-main>
      <vtoast ref="vtoast"/>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import qs from 'qs'
// eslint-disable-next-line no-unused-vars
import { User } from 'aveine-toolbox/src/store/models/User'
import { Ambassador } from 'aveine-toolbox/src/store/models/Ambassador'
import AveineLogin from 'aveine-toolbox/src/components/AveineLogin.vue'
import Vtoast from 'aveine-toolbox/src/components/Vtoast.vue'
import errorHandler from 'aveine-toolbox/src/store/errorHandler'
import { Tos, TosType } from 'aveine-toolbox/src/store/models/Tos'

export default defineComponent({
  name: 'App',

  components: {
    AveineLogin,
    Vtoast
  },
  data: () => ({
    toggleMenu: false as boolean,
    ambassadorMenu: false as boolean,
    agreedToTos: false as boolean,
    tosToSign: null as TosType | null,
    loadRoute: false as boolean
  }),
  watch: {
    /**
     * Watch the app language to reload tos in correct language
     */
    '$i18n.locale': async function() {
      if (this.tosToSign && this.tosToSign.id) {
        if(this.$auth.ambassador &&this.$auth.ambassador.id) {
          const tosRequest = await Tos.api("GET", `tos/ambassador/latest?lang=${this.$i18n.locale.toLowerCase()}`);
          this.tosToSign = tosRequest
        }
      }
    }
  },
  mounted() {
    const vtoast = this.$refs.vtoast
    this.$vtools.toast = vtoast
  },
  async created() {
    try {
      if (navigator && navigator.language && navigator.language.startsWith("fr")) {
        this.$i18n.locale = "FR"
      } else {
        this.$i18n.locale = "EN"
      }

      if (this.$vuetify.display.name == "xl") {
        this.toggleMenu = true;
      }

      const params = new URLSearchParams(window.location.search)
      const code = params.get("code")
      const state = decodeURIComponent(params.get("state") || "")
      const error = params.get("error")
      if (!code && !error && localStorage['aveine-token']) {
        this.$auth.isAuthenticated = true
        if (localStorage['aveine-authProfile']) {
          this.$auth.profile = JSON.parse(localStorage['aveine-authProfile'])
        }

        let user = await User.api("GET", `users/self?lang=${this.$i18n.locale}`, null, {include: "profile"})

        if (user.ambassador && user.ambassador.id) {
          const ambassadorReq = await Ambassador.getId(user.ambassador.id);
                  // if(user.ambassador && user.ambassador.id) {
          this.$auth.ambassador = ambassadorReq
          this.ambassadorMenu = true;
          const tosRequest = await Tos.api("GET", `tos/ambassador/latest?lang=${this.$i18n.locale.toLowerCase()}`);
          if (!tosRequest.meta["has-signed-latest-tos"])
            this.tosToSign = tosRequest
        } else {
          this.$router.replace("/registration")
        }
        this.$auth.roleLoaded = true;
        this.loadRoute = true;
        this.$auth.isLoading = false
      } else {
        if (state) {
          if (state != localStorage.routeToRedirectTo) {
            throw("Login error please try again")
          }
        } else {
          this.$router.replace("/")
        }
        if (code) {
          const authReq = await axios.post(`https://${process.env.VUE_APP_AUTH0_DOMAIN}/oauth/token`, qs.stringify({
            client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
            redirect_uri: `${window.location.origin}`,
            code,
            code_verifier: localStorage.codeVerifier,
            grant_type: "authorization_code"
          }), {headers: {"Content-Type": "application/x-www-form-urlencoded", "Auth0-Client": process.env.VUE_APP_AUTH0_CLIENT_ID}})
          if(authReq.data && authReq.data.access_token) {
            localStorage.setItem("aveine-token", authReq.data.access_token)
            var base64Url = authReq.data.access_token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            let profile = JSON.parse(jsonPayload)

            if (profile.nonce && profile.nonce != localStorage.nonce) {
              throw("Login error please try again")
            }
            profile = {name: profile.name ? profile.name : profile.nickname, email: profile.email, picture: profile.picture}
            localStorage.setItem("aveine-authProfile", JSON.stringify(profile))
            if (localStorage.routeToRedirectTo) {
              window.location.replace(localStorage.routeToRedirectTo);
              localStorage.removeItem("routeToRedirectTo");
            } else {
              window.location.reload();
            }
          }
        }
        this.loadRoute = true;
        this.$auth.isLoading = false
      }
    } catch (errors) {
      errorHandler(errors, this)
    }
  },
  methods: {
    /**
     * Sign the displayed tos
     */
    async signTos(tos) {
      try {
        await Tos.api("POST", `tos/${tos.id}/sign?lang=${this.$i18n.locale}`);
        this.tosToSign = null;
      } catch (errors) {
        errorHandler(errors, this)
      }
    }
  },
});
</script>

<style lang="scss">
  
  @import "aveine-toolbox/src/assets/style/aveine-style";
  @import "aveine-toolbox/src/assets/style/aveine-font";
  
  html,body{
    overflow-x: hidden;
  }

  div.app-container {
    padding: 0 !important;
    white-space: pre-line;
    overflow: hidden;
  }

  div.language-button-wrapper {
    width: 45px
  }

  .v-stepper-item, .v-stepper-item__content {
    width: 100%;
  }

  div.menu-button {
    background-color: var(--v-primary-base);
  }

  .main-search-bar input {
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    background-color: transparent;
    display: block;
    width: 30vw;
  }

  @media all and (max-width: 720px) {
    .v-dialog .v-overlay__content {
      margin: 0 !important;
      width: 100vw !important;
    }
    div.contract #contract-iframe {
      height: 500px;
    }
  }

  div.v-toolbar__content {
    padding-left: 0 !important;
    div.toolbar-left {
      height: inherit;
      div.menu-button {
        height: inherit;
      }
    }
  }

  div.profile-form-container {
    padding: 0 !important;
    overflow-y: scroll;
    height: 100%;
    max-height: 70vh !important;
    //Scrollbar style for Firefox
    scrollbar-color: var(--v-primary-base) var(--v-foreground);
    scrollbar-width: thin;
  }
  //Scrollbar style for webkit browsers
  div.profile-form-container::-webkit-scrollbar {
    width: 8px;
  }
  div.profile-form-container::-webkit-scrollbar-track {
    margin: 20px;
    background: var(--v-foreground);
    border-radius: 10px;
  }
  div.profile-form-container::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 10px;
  } 
  
  .v-slide-group__prev {
    display: none !important;
  }

  div.image-upload {
    p {
      margin-top: 5px;
    }

    div.upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }

    button.btn {
      background: #FFFFFF;
      border: 1px solid #a3a3a3;
      border-radius: 5px;
      padding: 5px 25px 5px 25px;
    }

    div.upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  nav.v-navigation-drawer {
    border-radius: 0px 50px 0px 0px;
    max-height: 100vh;
    position: fixed !important;
    .v-navigation-drawer__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    div.menu-button {
      button {
        color: white !important;
        background-color: transparent !important;
      }
    }
    div.v-list--dense {
      margin-bottom: 8vh;
    }
    div.nav-links {
      a {
        div.v-list-item__title {
          font-size: 0.9375rem !important;
          line-height: 1;
        }
      }
      i {
          margin-right: 7px;
      }
      margin-bottom: 10px;
      min-height: 40px;
    }
    div.v-list-item__title, a {
      color: white !important;
      text-decoration: none;
    }
    div#login {
      button {
        color: white;
        font-weight: 100 !important;
        i {
          margin-right: 7px;
        }
      }
    }
  }

  .avatar-image-preview {
    width: 20vw;
  }
  div.contact-us-button {
    position: absolute;
    bottom: 100px;
    left: 25px;
  }
  
  div.contract #contract-iframe {
    width: 100%;
    height: 500px;
  }
</style>