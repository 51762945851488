<template>
	<div class="login login-header d-flex flex-row justify-center">
		<v-img v-if="$vuetify.display.name == 'xl' || $vuetify.display.name == 'lg'" class="banner-img" src="@/assets/login.jpg" alt=""/>
		<div class="d-flex flex-column pl-xl-10 pl-lg-8 header-banner justify-xl-center justify-lg-center">
			<v-list-item class="d-flex pl-0">
				<v-select
							v-model="$i18n.locale"
							:items="['FR', 'EN']"
							single-line
				>
				</v-select>
			</v-list-item>
			<v-img
				alt="Aveine"
				src="@/assets/logo-aveine-white.png"
				width="360"
				max-height="50"
			/>
			<h1 class="mt-6">
				{{$t("aveine-space.homepage.ambassadorTitle")}}
			</h1>
			<h2 class="font-weight-regular mb-6">
				{{$t("aveine-space.homepage.ambassadorText")}}
			</h2>
			<div class="mt-xl-8 mt-lg-6 mt-4">
				<v-btn :to="{ name: 'Registration'}">{{$t("aveine-space.onBoarding.startRegistration")}}</v-btn>
				<AveineLogin/>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import AveineLogin  from 'aveine-toolbox/src/components/AveineLogin.vue'
  import errorHandler  from 'aveine-toolbox/src/store/errorHandler'
	import { Invitation } from 'aveine-toolbox/src/store/models/Invitation'
  export default defineComponent({
	name: 'LoginPage',
  data () {
    return {
			invitationEmail: null as string | null
    }
	},
	components:{
		AveineLogin
	},
	computed: {
	},
  methods: {
		/**
		 * Submit the become ambassador form and send an invitation
		 *
		 * @return {void}
		 */
		async becomeAmbassadorSubmit(dialog) {
			try {
				Invitation.api("POST", `pro/invitations/ambassador`, {email: this.invitationEmail});
				this.$vtools.toast.show({title: this.$t("aveine-space.title.invitationSent")});
				this.invitationEmail = null;
				dialog.value = false;
      } catch (errors) {
        errorHandler(errors, this)
      }
		},
  },


		mounted () {
			if (this.$auth.roleLoaded) {
				if (localStorage.routeToRedirectTo) {
					this.$router.push(localStorage.routeToRedirectTo)
					localStorage.removeItem("routeToRedirectTo");
				} else if (this.$auth.isAuthenticated) {
					this.$router.push('/authenticated/pro/dashboard');
				}
			}
		}
  })
</script>

<style lang="scss">
	@import url("https://use.typekit.net/yff7lve.css");
		
		div.login-header {
			display: flex;
			white-space: pre-line;
			height: 100vh;
			min-height: 600px;
			background-color: rgb(var(--v-theme-primary));
			color: white;
			.banner-img {
				width: 42vw;
				height: 100vw;
			}
			div.header-banner {
				width: 60vw;
				h1 {
					font-weight: bold;
					font-size: 25px;
					line-height: 1.3em;
					margin-bottom: 100px !important;
				}
				h2 {
					font-size: 18px;
					line-height: 1.6em;
					max-width: 40vw;
				}
				button {
					font-weight: bolder;
					background-color: var(--v-secondary-base);
				}
				#login {
					margin: 0;
					position: relative;
					top: 40px;
					z-index: 10;
					color: transparent !important;
					.login-btn {
						color: white;
						border: 1px solid white;
						border-radius: 10px;
						margin-top: 10px;
						span {
							padding-left: 30px;
							padding-right: 10px;
						}
						i:before {
							content: "";
						}
					}
				}
			}
		}

		@media only screen and (max-width: 960px) {
			div.login-header {
				height: 120vh;
				img.banner-img {
					display: none;
				}
				div.header-banner {
					width: 100vw !important;
					height: 120vw;
					padding: 20px;
					margin-top: 20px;
					h2 {
						max-width: 100vw;
					}
					.login-btn {
						span {
							font-size: 14px;
						}
					}
				}
			}
		}
</style>
