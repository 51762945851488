import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-613949cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-xl-row flex-lg-row flex-column home my-5" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-column justify-xl-center mx-2 align-xl-center"
}
const _hoisted_3 = { class: "column-1" }
const _hoisted_4 = { class: "d-flex flex-column justify-xl-center mx-2 align-center" }
const _hoisted_5 = {
  key: 0,
  class: "header mx-2"
}
const _hoisted_6 = { class: "d-flex justify-center" }
const _hoisted_7 = { class: "ma-1 text-light" }
const _hoisted_8 = { class: "ma-1" }
const _hoisted_9 = { class: "d-flex mx-2" }
const _hoisted_10 = { class: "mb-4 ml-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AveineSaleCharts = _resolveComponent("AveineSaleCharts")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AveineSaleDetails = _resolveComponent("AveineSaleDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$vuetify.display.name == 'xl' || _ctx.$vuetify.display.name == 'lg')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_card, { class: "d-flex pa-6 px-xl-14 px-lg-12 rounded-lg chart-container" }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t("aveine-space.title.page.mySales")), 1),
                _createVNode(_component_AveineSaleCharts, {
                  class: "pt-8 pl-6 component",
                  dashboardActions: true,
                  ambassadorId: _ctx.$auth.ambassador.id,
                  discountCode: _ctx.$auth.ambassador.promotion_code,
                  selectedCurrency: _ctx.selectedCurrency
                }, null, 8, ["ambassadorId", "discountCode", "selectedCurrency"])
              ], undefined, true),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.$vuetify.display.name == 'xl' || _ctx.$vuetify.display.name == 'lg')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.$auth.ambassador)
                ? (_openBlock(), _createBlock(_component_v_card, {
                    key: 0,
                    class: "py-6 px-14 rounded-lg discount-code-card"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { class: "discount-code d-flex flex-column align-center mt-4" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("aveine-space.sales.discountCode")), 1),
                          _createTextVNode(),
                          _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$auth.ambassador.promotion_code), 1)
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_v_card, { class: "sale-details rounded-lg text-left align-start" }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t("aveine-space.dashboard.ambassadorSales")), 1),
            _createVNode(_component_AveineSaleDetails, {
              class: "component",
              selectedCurrency: _ctx.selectedCurrency,
              monthSalesNumber: _ctx.monthSalesNumber,
              yearSalesNumber: _ctx.yearSalesNumber,
              totalSalesNumber: _ctx.totalSalesNumber,
              totalIncome: _ctx.totalIncome,
              pendingIncome: _ctx.pendingIncome,
              adminMode: true
            }, null, 8, ["selectedCurrency", "monthSalesNumber", "yearSalesNumber", "totalSalesNumber", "totalIncome", "pendingIncome"])
          ], undefined, true),
          _: 1
        })
      ])
    ])
  ]))
}