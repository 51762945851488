<template>
  <div class="home py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.editMyInformation")}}</span>
    <div v-if="dataLoaded" class="form-container">
      <AveineAmbassadorForm :APIbaseURL="APIbaseURL" :ambassador="ambassador" />
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AveineAmbassadorForm  from 'aveine-ambassador/src/components/AveineAmbassadorForm.vue'
import { Ambassador, AmbassadorType } from 'aveine-toolbox/src/store/models/Ambassador'
import errorHandler  from 'aveine-toolbox/src/store/errorHandler'
export default defineComponent({
  data () {
    return {
      ambassador: {} as AmbassadorType,
      dataLoaded: false as boolean,
    }
  },
  name: 'UpdateSelfAmbassador',
  components: {
    AveineAmbassadorForm
  },
  async created() {
    try {
      const ambassadorFetch = await Ambassador.getId(this.$route.params.id as string, {
          include:
          'address.country,address.spatial-information,' +
          'company.company-contact,company.address.spatial-information',
        }
      );

      this.ambassador = ambassadorFetch

      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  }
})
</script>
