<template>
  <div class="home py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.createInvoice")}}</span>
    <div v-if="dataLoaded">
      <AveineInvoiceForm :APIbaseURL="APIbaseURL" :invoice="invoice" :invoiceSummary="invoiceSummary" :ambassador="ambassador" />
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import  AveineInvoiceForm  from 'aveine-invoice/src/components/AveineInvoiceForm.vue'
import { Invoice, InvoiceType } from 'aveine-toolbox/src/store/models/Invoice'
import { Ambassador, AmbassadorType } from 'aveine-toolbox/src/store/models/Ambassador'
export default defineComponent({
  data () {
    return {
      invoice: {} as InvoiceType,
      invoiceSummary: {} as any,
      ambassador: {} as AmbassadorType,
      dataLoaded: false as boolean
    }
  },
  name: 'CreateInvoice',
  components: {
    AveineInvoiceForm
  },
  async created() {
    if (this.$auth.ambassador && this.$auth.ambassador.id) {
      let invoiceSummary = await Invoice.api("GET", `orders/ambassadors/${this.$route.params.id}/summary`)

      this.invoiceSummary = invoiceSummary.meta;

      let ambassadorFetch = await Ambassador.getId(this.$route.params.id as string);

      this.ambassador = ambassadorFetch

      this.invoice = (new Invoice).defaults

      this.invoice.currency = "EUR"
      this.dataLoaded = true;
    } else {
      this.$router.replace("/")
    }
  },
})
</script>
<style>
  @media (min-width: 1280px) {
    .home {
      max-width: 80vw;
    }
  }
</style>