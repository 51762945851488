<template>
  <div class="stepper-register-container">
    <div v-if="dataLoaded">
      <div class="d-flex align-center justify-center ma-xl-16 ma-lg-10 ma-0 registration-container">
        <v-stepper class="stepper-container" v-model="currentStep" :key="currentStep">
            <v-stepper-header class="pa-10 px-xl-16 px-lg-14 px-4" v-if="!errorHasOccured">
              <div class="d-flex flex-column text-center align-center header-step-number">
                <span :class="['step-number', currentStep == 1 ? 'active' : '']" >1</span>
                <v-stepper-step :complete="currentStep > 1" :value="1" :step="1">{{$t("aveine-space.onBoarding.login")}}</v-stepper-step>
              </div>
              <div class="step-divider"></div>
              <div class="step-divider"></div>
              <div class="d-flex flex-column text-center align-center header-step-number">
                <span :class="['step-number', currentStep == 2 ? 'active' : '']" >2</span>
                <v-stepper-step :complete="currentStep > 2 || tosAlreadySigned" :value="2" :step="2">{{$t("aveine-space.onBoarding.tos")}}</v-stepper-step>
              </div>
              <div class="step-divider"></div>
              <div class="step-divider"></div>
              <div class="d-flex flex-column text-center align-center header-step-number">
                <span :class="['step-number', currentStep == 3 ? 'active' : '']" >3</span>
                <v-stepper-step :complete="currentStep > 3 || (ambassador && ambassador.name) ? true : false" :value="3" :step="3">{{$t("aveine-space.onBoarding.accountInfo")}}</v-stepper-step>
              </div>
              <v-stepper-step :complete="currentStep > 4" :value="4" :step="4"></v-stepper-step>
            </v-stepper-header>
              <v-stepper-item v-if="currentStep == 1" :value="1">
                <div class="d-flex flex-column ma-xl-16 ma-lg-10 ma-4 justify-center align-center">
                  <h1 v-if="!roleAlreadyAssigned && !errorHasOccured" class="my-6" color="primary" size="100">{{$t('aveine-space.onBoarding.welcome')}}</h1>
                  <h1 v-else class="my-6"><v-icon color="secondary" size="100">mdi-alert-circle-outline</v-icon></h1>
                  <div class="d-flex flex-column ma-6 justify-center align-center" v-if="errorHasOccured">
                    <h3 class="text-center">
                      {{$t('aveine-space.errors.registrationError')}}<br>
                      <a href="mailto:ambassadeurs@aveine.paris">ambassadeurs@aveine.paris</a>
                    </h3>
                  </div>
                  <div class="d-flex flex-column ma-6 px-xl-16 justify-center align-center" v-else-if="roleAlreadyAssigned">
                    <h4 class="text-center mx-xl-16 mx-lg-10 mb-8">
                      {{$t('aveine-space.errors.roleAlreadyAssigned')}}
                    </h4>
                    <span class="text-center mx-xl-16 mx-lg-10 mb-8">
                      <a href="/">{{$t("aveine-space.onBoarding.backToIndex")}}</a>
                    </span>
                  </div>
                  <div class="d-flex flex-column ma-6 px-xl-16 justify-center align-center" v-else>
                    <h4 v-html="$t('aveine-space.onBoarding.introText')" class="text-center mx-xl-16 mx-lg-10 mb-8">
                    </h4>
                    <p v-if="!$auth.isAuthenticated" class="text-center mx-xl-16 mx-lg-10 mb-8">
                      {{$t("aveine-space.onBoarding.userNotLoggedIn")}}
                      <AveineLogin class="my-5" :signingUp="true"/>
                    </p>
                    <div class="d-flex justify-center" v-if="!roleAlreadyAssigned && !errorHasOccured && $auth.isAuthenticated">
                      <v-btn
                        :disabled="!$auth.isAuthenticated"
                        class="ma-6"
                        color="primary"
                        @click="currentStep += 1"
                      >
                        {{$t("aveine-space.onBoarding.start")}}
                      </v-btn>
                    </div>
                    <v-list-item class="d-flex justify-center">
                      <v-select
                            v-model="$i18n.locale"
                            :items="['FR', 'EN']"
                            single-line
                      >
                      </v-select>
                    </v-list-item>
                    <p v-if="$auth.isAuthenticated">
                      <AveineLogin :key="$auth.isLoading" />
                    </p>
                  </div>
                </div>
              </v-stepper-item>
              <v-stepper-item v-if="currentStep == 2"  :value="2">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row align-center contract">
                    <iframe id="contract-iframe" :src="latestTos.name + '#page=1&zoom=80'"></iframe>
                  </div>
                  <div class="d-flex mt-3 justify-start">
                    <v-checkbox v-model="agreedToTos" :label="$t('aveine-space.onBoarding.agreeTos')"/>
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <v-btn raised
                    variant="text"
                    class="ma-6"
                    color="primary"
                    @click="currentStep -= 1">
                    {{$t("aveine-space.onBoarding.back")}}
                  </v-btn>

                  <v-btn
                    :disabled="!agreedToTos"
                    class="ma-6 justify-end"
                    color="primary"
                    @click="agreeTos"
                  >
                    {{$t("aveine-space.onBoarding.next")}}
                  </v-btn>
                </div>
              </v-stepper-item>

              <v-stepper-item v-if="currentStep == 3 && $auth.isAuthenticated" :value="3">
                <div class="d-flex flex-column ma-xl-6">
                  <AveineAmbassadorForm
                    :APIbaseURL="APIbaseURL"
                    :ambassador="ambassador"
                    :callback="savedInformations"
                  />
                </div>
                <div>
                  <v-btn raised
                    variant="text"
                    class="ma-6"
                    color="primary"
                    @click="currentStep -= 1">
                    {{$t("aveine-space.onBoarding.back")}}
                  </v-btn>
                </div>
              </v-stepper-item>

              <v-stepper-item :value="4" v-if="currentStep == 4 && $auth.isAuthenticated">
                <div class="ma-8 d-flex flex-column justify-center align-center step-content">
                  <v-icon size="64" color="primary">mdi-check-circle</v-icon>
                  <h4 class="text-center">{{$t("aveine-space.onBoarding.signInSuccess")}}</h4>
                  <div class="d-flex flex-column justify-center">
                    <v-btn raised
                      variant="text"
                      class="ma-6"
                      color="primary"
                      @click="goHome()">
                      {{$t("aveine-space.onBoarding.backToIndex")}}
                    </v-btn>
                  </div>
                </div>
              </v-stepper-item>
        </v-stepper>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { User } from 'aveine-toolbox/src/store/models/User'
  import { Tos, TosType } from 'aveine-toolbox/src/store/models/Tos';
  import { Ambassador, AmbassadorType } from 'aveine-toolbox/src/store/models/Ambassador';
  import errorHandler  from 'aveine-toolbox/src/store/errorHandler'
  import AveineLogin  from 'aveine-toolbox/src/components/AveineLogin.vue'
  import AveineAmbassadorForm  from 'aveine-ambassador/src/components/AveineAmbassadorForm.vue'

  export default defineComponent({
    name: 'registrationPage',
    components: {
      AveineLogin,
      AveineAmbassadorForm
    },
    data () {
      return {
        /**
         * The ambassador ressource associated with the invitation
         * 
         * @type {Object}
         */
        ambassador: {} as AmbassadorType,
        /**
         * The latest terms of service to sign
         * 
         * @type {Object}
         */
        latestTos: {} as TosType,
        /**
         * Flag determining if the user has already agreed to the tos in the past
         * 
         * @type {Boolean}
         */
        tosAlreadySigned: false as boolean,
        /**
         * Flag determining if the user has agreed to the term of services
         * 
         * @type {Boolean}
         */
        agreedToTos: false as boolean,
        /**
         * Internal state to tell if the user is trying to register for a role he already has
         *
         * @type {boolean}
         */
        roleAlreadyAssigned: false as boolean,
        /**
         * Track stepper current step
         *
         * @type {number}
         */
        currentStep: 1 as number,
        /**
         * Flag to determine if the shopify store button is loading to display a loading animation
         * 
         * @type {Boolean}
         */
        storeButtonLoading: false as boolean,
        /**
         * Tracking current shopify store selected
         *
         * @type {String}
         */
        shopifyStore: null as string | null,
        /**
         * Internal state to know when to display the payment step
         *
         * @type {boolean}
         */
        showPaymentStep: false as boolean,
        /**
         * Flag determining if the user has already payed for his ambassador kit before
         * 
         * @type {Boolean}
         */
        paymentAlreadyDone: false as boolean,
        /**
         * Stores the checkout token got from the shopify checkout process
         * 
         * @type {String}
         */
        checkoutToken: null as string | null,
        /**
         * Regular expression to extract purchase code from a checkoutCreate response
         *
         * @type {RegExp}
         */
        checkoutTokenRegex: /gid:\/\/shopify\/Checkout\/(?<checkoutToken>[^?|/]+)/,
        /**
         * Regular expression to extract purchase code from a shopify shop from checkoutCreate response
         *
         * @type {RegExp}
         */
        webUrlRegex: /https:\/\/(?<shop>[^.]+).myshopify.com/,
        /**
         * Flag to determine if the form is correcly filled
         * 
         * @type {Boolean}
         */
        isFormValid: false as boolean,
        /**
         * Internal state to tell if any error has been catched in the view create method
         * 
         * @type {Boolean}
         */
        errorHasOccured: false as boolean,
        /**
         * Flag to determine if all of the route related data has been loaded
         * 
         * @type {Boolean}
         */
        dataLoaded: false as boolean
      }
    },
    watch: {
      /**
       * Update terms of services (called on language changes)
       */
      '$i18n.locale': async function() {
        const fetchTos = await Tos.api("GET", `/tos/ambassador/latest?lang=${this.$i18n.locale.toLowerCase()}`);
        this.latestTos.name = fetchTos.name;
      },
    },

    async created () {
      try {
        if (this.$route.query.lang == "en") {
          this.$i18n.locale = 'en';
        }
        if (this.$auth.isAuthenticated) {
          // in the case where the user has started the registration process for a new role but didn't finish it check if the auth0 role has been assigned or not
          if (this.$auth.ambassador && this.$auth.ambassador.id){
            this.roleAlreadyAssigned = true;
          } else {
            this.ambassador = (new Ambassador).defaults

            const tosRequest = await Tos.api("GET", `tos/ambassador/latest?lang=${this.$i18n.locale.toLowerCase()}`);
            this.latestTos = tosRequest;

            if (tosRequest.meta["has-signed-latest-tos"]) {
              this.tosAlreadySigned = true;
              this.agreedToTos = true;
            }
          }
        }
        this.dataLoaded = true;
      } catch (errors) {
        errorHandler(errors, this)
        this.errorHasOccured = true;
        this.dataLoaded = true;
      }
    },
    methods: {
      /**
       * Callback called when the user has agreed to the latest terms of service
       */
      async agreeTos() {
        try {
          await Tos.api("POST", `/tos/${this.latestTos.id}/sign?lang=${this.$i18n.locale.toLowerCase()}`, null);
          this.currentStep += 1
        } catch (errors) {
          errorHandler(errors, this)
        }
      },
      /**
       * Callback called when the user has saved his informations
       */
      async savedInformations() {
        try {
          await User.api("GET", `users/self?lang=${this.$i18n.locale.toLowerCase()}`, null, {include: "profile"})
          this.currentStep = this.currentStep + 1;
        } catch (errors) {
          errorHandler(errors, this)
        }
      },
      async goHome() {
        window.location.replace("/")
      }
    }
  })
</script>
<style lang="scss">
  div.stepper-register-container {
    min-height: 95vh !important;
    margin-top: 0px !important;
    div.registration-container {
      margin-top: 3vw;
      .stepper-container {
        width: 80vw;
        background-color: rgb(var(--v-theme-beige));
      }
      h1 {
        font-family: 'ivypresto-display', sans-serif;
        font-weight: normal;
        text-align: left;
        font-size: 42px;
        line-height: 1.3em;
      }
      div.header-step-number {
        height: 5em
      }
      .v-stepper-item__avatar {
        display: none;
      }
      span.step-number {
        width: 50px;
        height: 50px;
        background: transparent;
        color: rgb(var(--v-theme-primary));
        font-weight: bold;
        border: 1px solid rgb(var(--v-theme-primary));
        border-radius: 10px;
        text-align: center;
        font-size: 23px;
        padding-top: 5px;
        margin-bottom: 5px;
      }

      span.step-number.active {
        background: rgb(var(--v-theme-primary));
        color: rgb(var(--v-theme-beige));
      }

      div.step-divider {
        height: 1px;
        width: 15vw;
        background: rgb(var(--v-theme-primary));
      }

      button.login-btn {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        min-height: 36px!important;
        min-width: 88px!important;
        text-transform: uppercase!important;
        font-weight: 500!important;
        font-size: 14px!important;
        color: #333!important;
        background-color: #fff!important;
      }
      button.login-btn:focus {
        background-color: #fcfcfc;
      }
      button.login-btn:hover {
        background-color: #fcfcfc;
      }
      button.login-btn:active {
        background-color: #fcfcfc;
      }
    }
  }

  @media screen and (max-width: 960px) {
  .stepper-container {
    width: 98vw !important;
    padding-bottom: 5vh;
  }

  .v-stepper__content {
    padding: 10px !important;
  }

  div.contract #contract-iframe {
    width: 98vw !important;
    padding: 0 !important;
  }
  md-steppers-header {
    button:not(.md-active) {
     display: none; 
    }
  }
}
</style>

