<template>
  <router-view></router-view>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  export default {
    name: 'ProPages'
  }
</script>
