import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo-aveine.png'
import _imports_1 from '@/assets/logo-aveine-white.png'


const _hoisted_1 = { class: "d-flex align-center toolbar-left" }
const _hoisted_2 = { class: "d-flex align-center menu-button" }
const _hoisted_3 = { class: "d-flex align-center toolbar-left" }
const _hoisted_4 = { class: "d-flex align-center menu-button" }
const _hoisted_5 = { class: "contract ma-xl-6 ma-lg-4" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "d-flex align-center toolbar-left"
}
const _hoisted_8 = { class: "d-flex align-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_AveineButtonLanguage = _resolveComponent("AveineButtonLanguage")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_AveineLogin = _resolveComponent("AveineLogin")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_vtoast = _resolveComponent("vtoast")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (!_ctx.$auth.loading && _ctx.$auth.isAuthenticated && _ctx.$route.name != 'Registration' && _ctx.$vuetify.display.name != 'xl' && _ctx.$vuetify.display.name != 'lg')
        ? (_openBlock(), _createBlock(_component_v_app_bar, {
            key: 0,
            elevation: "10"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleMenu = !_ctx.toggleMenu), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-menu")
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ])
              ]),
              _createVNode(_component_v_spacer),
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, { to: "/authenticated/dashboard" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      alt: "Aveine",
                      class: "shrink mt-1",
                      cover: "",
                      "min-width": "135",
                      src: _imports_0,
                      width: "100"
                    })
                  ], undefined, true),
                  _: 1
                })
              ]),
              _createVNode(_component_v_spacer),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    white: "",
                    disabled: ""
                  })
                ])
              ])
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_ctx.tosToSign && _ctx.tosToSign.id)
            ? (_openBlock(), _createBlock(_component_v_dialog, {
                key: 0,
                modelValue: _ctx.tosToSign,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tosToSign) = $event)),
                transition: "dialog-bottom-transition",
                "max-width": "1200",
                class: "mx-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar, {
                        class: "text-h6 px-6",
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("aveine-space.title.newTosAvailable")) + " ", 1),
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_AveineButtonLanguage)
                        ], undefined, true),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, { class: "px-xl-16 px-lg-14 px-1" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_v_list_item, { class: "d-flex justify-center" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  modelValue: _ctx.$i18n.locale,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                                  items: ['FR', 'EN'],
                                  "single-line": ""
                                }, null, 8, ["modelValue"])
                              ], undefined, true),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("iframe", {
                                id: "contract-iframe",
                                src: _ctx.tosToSign.name + '#page=1&zoom=80'
                              }, null, 8, _hoisted_6)
                            ]),
                            _createVNode(_component_v_checkbox, {
                              modelValue: _ctx.agreedToTos,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.agreedToTos) = $event)),
                              label: _ctx.$t('aveine-space.onBoarding.agreeTos')
                            }, null, 8, ["modelValue", "label"])
                          ])
                        ], undefined, true),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, { class: "justify-end" }, {
                        default: _withCtx(() => [
                          (_ctx.agreedToTos)
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                color: "primary",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.signTos(_ctx.tosToSign)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("aveine-space.title.submit")), 1)
                                ], undefined, true),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (!_ctx.$auth.loading && _ctx.$auth.isAuthenticated && _ctx.$route.name != 'Registration')
            ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
                key: 1,
                class: "menu-drawer d-flex align-space-between",
                color: "primary",
                modelValue: _ctx.toggleMenu,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.toggleMenu) = $event)),
                absolute: "",
                elevation: 1
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (_ctx.$vuetify.display.name == 'xl' || _ctx.$vuetify.display.name == 'lg')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_router_link, { to: "/authenticated/dashboard" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_img, {
                                  alt: "Aveine",
                                  class: "shrink ma-6 mt-7",
                                  cover: "",
                                  "min-width": "180",
                                  src: _imports_1,
                                  width: "100"
                                })
                              ], undefined, true),
                              _: 1
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_list, { density: "compact" }, {
                      default: _withCtx(() => [
                        (_ctx.$auth.ambassador && _ctx.ambassadorMenu)
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 0,
                              class: "nav-links ml-6 mt-4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, { to: { name: 'AmbassadorDashboard'} }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { color: "white" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-home")
                                          ], undefined, true),
                                          _: 1
                                        }),
                                        _createTextVNode(_toDisplayString(_ctx.$t("aveine-space.title.menu.ambassadorDashboard")), 1)
                                      ], undefined, true),
                                      _: 1
                                    })
                                  ], undefined, true),
                                  _: 1
                                })
                              ], undefined, true),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.$auth.ambassador && _ctx.ambassadorMenu)
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 1,
                              class: "nav-links ml-6 mt-4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, {
                                  to: { name: 'AmbassadorSales', params: { id: _ctx.$auth.ambassador.id } }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { color: "white" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-currency-usd")
                                          ], undefined, true),
                                          _: 1
                                        }),
                                        _createTextVNode(_toDisplayString(_ctx.$t("aveine-space.title.menu.mySales")), 1)
                                      ], undefined, true),
                                      _: 1
                                    })
                                  ], undefined, true),
                                  _: 1
                                }, 8, ["to"])
                              ], undefined, true),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.$auth.ambassador && _ctx.ambassadorMenu)
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 2,
                              class: "nav-links ml-6 mt-4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, {
                                  to: { name: 'Invoices', params: { id: _ctx.$auth.ambassador.id } }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { color: "white" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-file")
                                          ], undefined, true),
                                          _: 1
                                        }),
                                        _createTextVNode(_toDisplayString(_ctx.$t("aveine-space.title.menu.invoices")), 1)
                                      ], undefined, true),
                                      _: 1
                                    })
                                  ], undefined, true),
                                  _: 1
                                }, 8, ["to"])
                              ], undefined, true),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], undefined, true),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_v_list, { density: "compact" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, { class: "text-center d-flex justify-center" }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_component_AveineLogin, {
                            key: _ctx.$auth.isLoading
                          }))
                        ], undefined, true),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, { class: "d-flex justify-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.$i18n.locale,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                            items: ['FR', 'EN'],
                            "single-line": ""
                          }, null, 8, ["modelValue"])
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.loadRoute)
            ? (_openBlock(), _createBlock(_component_v_container, {
                key: 2,
                fluid: "",
                class: "app-container"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_router_view, {
                    key: _ctx.$route.fullPath
                  }))
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_vtoast, { ref: "vtoast" }, null, 512)
    ], undefined, true),
    _: 1
  }))
}