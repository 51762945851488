export default {
  "en": {
    "homepage": {
      "tagline": "Manage your favorite wines\nwith ease!",
      "taglineSubtitle":"By the creators of the Aveine Wine Aerator, discover the Aveine Space.\nKeep track of your wine tasting and \ndiscover your next favorite bottle!",
      "discover": "I Discover",
      "login": "I Login",
      "advantagesTitle": "The Wine Social Network",
      "manageWinesTitle": "Manage your Wines",
      "manageWinesText": "Keep track of your favorite wines, the last wines you tasted and learn more information about them.",
      "recommendationTitle": "Receive personalized\nrecommendations",
      "recommendationText": "Fill in a tasting profile with the types of wines you enjoy the most and we'll recommend you new bottles to taste everyday.",
      "friendsTitle": "See your friends\ntastings",
      "friendsText": "Get in touch with your friends, see what they are drinking and what they recommend.",
      "rewardsTitle": "Get rewarded using\nthe App",
      "rewardsText": "Get special badges on your profile by scanning wines, writing reviews and using the app in general.",
      "ambassadorTitle": "AMBASSADOR PROGRAM",
      "ambassadorText": "Tell your friends and contacts about the Aveine Original aerator. Thanks to your discount code, they'll get an exclusive 10% discount on the purchase of an Aveine aerator, and you'll receive a €42 commission. \n\n Whatever your field of activity, you can become an Aveine Ambassador. All you need is a structure (self-employed, company) that allows you to issue invoices.",
      "curiousAboutAmbassadorProgram": "Curious about the Ambassador Program?",
      "learnMore": "Learn more",
      "aboutAveineTitle": "About Aveine",
      "aboutAveineText": "Aveine is the first smart wine aerator, it allows you to aerate your wine instantly and\nto perfection, for and optimal wine tasting experience. Already adopted by\nmany profesionals and it seduces countless wine enthusiasts."
    },
    "title": {
      "aveine": "Aveine",
      "taxIncluded": "Excluding tax",
      "myAmbassadorsList": "My ambassadors",
      "contactMessage": "For any question about the Ambassador program or any tecnnical difficulties, please contact us via this address: ",
      "becomeAmbassador": "Become an ambassador",
      "becomeAmbassadorMessage": "Please enter your email address, you'll receive an invitation to register as an ambassador",
      "email": "Email",
      "invitationSent": "Invitation sent",
      "tosSignature": "Terms of Services has been updated",
      "editProfile": "Edit Profile",
      "declareRevenue": "Declare revenue",
      "declare": "Declare",
      "websitePromo": "The latest <br> news about Aveine",
      "accessWebsite": "See more",
      "newTosAvailable": "Terms of Service has been updated",
      "agreeTos": "I accept the terms of service",
      "submit": "Submit",
      "send": "Send",
      "profileSaved": "Profile saved",
      "invitationEmail": "Email to send the invitation to",
      "cookieLaw": {
        "message": "This website uses cookies to ensure you get the best experience on our website.",
        "privacyPolicy": "Privacy Policy",
        "accept": "Accept",
        "refuse": "Decline"
      },
      "menu": {
        "navigation": "Navigation",
        "home": "Home",
        "ambassadorDashboard": "Dashboard",
        "editMyInformation": "Edit my Information",
        "invoices": "My Invoices",
        "mySales": "My Sales",
        "contactUs": "Contact Us"
      },
      "page": {
        "home": "Dashboard",
        "editMyInformation": "Edit my Information",
        "myInvoices": "My Invoices",
        "mySales": "My Sales",
        "createInvoice": "Issue an invoice",
        "declaredSales": "Declared sales"
      },
    },
    "profile": {
      "profile": "Profile",
      "editProfile": "Edit Profile",
      "editTastingProfile": "Edit Tasting Profile",
      "followings": "Followings",
      "followers": "Followers",
      "name": "User name",
      "description": "Description",
      "language": {
        "en": "English",
        "fr": "Français"
      },
      "button": {
        "deleteImage": "Remove avatar",
        "submit": "Submit",
        "uploadFile": "Upload a file",
        "cancel": "Cancel",
        "showcase": "Showcase"
      }
    },
    "dashboard": {
      "submitAmbassadorInvoice": "Submit an Ambassador invoice",
      "submitSuperAmbassadorInvoice": "Submit a Super Ambassador invoice",
      "ambassadorInvoices": "Ambassador invoice in progress",
      "superAmbassadorInvoices": "Super Ambassador invoice in progress",
      "domainsWines": "My wines",
      "ambassadorSales": "Total sales",
      "sales": "Sales",
      "superAmbassadorSales": "My Ambassador's sales",
      "seeMore": "See more"
    },
    "invoices": {
      "ambassadorInvoices": "Ambassador Invoices",
      "superAmbassadorInvoices": "Super Ambassador Invoices",
      "totalDeclared": "Total income declared"
    },
    "sales": {
      "title": "My sales",
      "discountCode": "Discount code",
      "currency": "Currency",
      "unlockedMoney": "Declarable income available",
      "lockedMoney": "Pending income",
      "unlockedAt": "unlocked at"
    },
    "onBoarding": {
      "start": "Start",
      "next": "Next",
      "back": "Back",
      "submit": "Submit",
      "processing": "Processing...",
      "skip": "Skip",
      "introduction": "Introduction",
      "welcome": "Welcome",
      "introText": "You have been invited to become an Aveine Ambassador, you will be guided through the process of establishing your new status.",
      "userNotLoggedIn": "Sign in to begin",
      "existingAccount": "An Aveine account is necessary to continue the registration process.\nPlease login or create an account by clicking on the button below : ",
      "createProAccount": "Create an account",
      "searchDomain": "Find your domain",
      "selectDomainPrompt": "Please search for your domain first (if you can't find it you can ignore this step to add it)",
      "userDomainField": "Domain",
      "domainValidation": "We couldn't find your domain in our database",
      "domainAlreadyRegistered": "This domain is already registered",
      "login": "Login",
      "tos": "Terms of service",
      "agreeTos": "I agree to the terms of service",
      "accountInfo": "Account information",
      "complementaryInfo": "Additional information",
      "payment": "Payment",
      "checkout": "Order your kit",
      "orderButton": "Order",
      "startRegistration": "Become an ambassador",
      "signIn": "Sign in",
      "logIn": "Log in",
      "storeSelection": "To become an Aveine Ambassador you will need an Ambassador kit containing an aerator, your personalized promo code, as well as various promotional tools.",
      "willShipTo": "the kit will ship to : ",
      "france": "France",
      "europe": "Europe",
      "northAmerica": "North America",
      "international": "Other",
      "alreadyHaveAPurchaseCode": "After the checkout is done you'll receive a purchase code, if you already have it, please fill in the field down below to continue to the next step of the process.",
      "purchaseCode": "Enter a purchase code",
      "purchaseCodeToolTip": "The purchase code can be found on the order confirmation page.",
      "accountCreationInfo": "To finalize the account creation, please enter a valid email address and a password. You will use these informations to log into the Aveine Pro Space :",
      "personalInfoText": "As the last step, fill in your activity information.\nThese information will be public and will appear on Aveine mobile and web applications.\n (*only name, company and address are mandatory)",
      "finalization": "Finalization",
      "signInSuccess": "Welcome to the Aveine Ambassador Program!",
      "proceedWithLogin": "You can now click on the button below to log in",
      "backToIndex": "Go back to the home page",
      "passwordChecking" : {
        "characterCount": "- At least 8 characters in length",
        "upperCase": "- An upper case",
        "lowerCase": "- A lower case",
        "digit": "- A digit",
        "special": "- A special character"
      }
    },
    "errors": {
      "error": "Error",
      "inputRequired": "This field is required",
      "cantRetrieveSelf": "Can't retrieve the related ambassador account",
      "errorPassword": "Passwords don't match",
      "emailValidation": "Invalid email",
      "passwordValidation": "The password doesn't fulfill the conditions",
      "registrationError": "An unexpeted error has occured, please refresh the page.\nIf the problem persists please contact our team",
      "invitationAlreadyConsumed": "The invitation you're trying to open has already been used or has expired",
      "roleAlreadyAssigned": "You already possess this role",
      "noPurchaseCode": "A purchase code is required to continue",
      "notProError": "Your account doesn't have the permissions to access the Aveine Pro Space functionalities",
      "pageNotFound": "Page not found",
      "unknownError": "Unknown Error"
    }
  },
  "fr": {
    "homepage": {
      "tagline": "Gérez vos vins préférés\nen un seul clic ! ",
      "taglineSubtitle":"Accompagnant l'aérateur Aveine, découvrez l'Espace Aveine.\nSuivez vos dégustations de vins et découvrez\nvos prochaines bouteilles favorites ! ",
      "discover": "Je découvre",
      "login": "Je me connecte",
      "advantagesTitle": "Le réseau social du vin",
      "manageWinesTitle": "Gérez vos\nvins",
      "manageWinesText": "Gardez une trace de vos vins favoris, des dernières bouteilles que vous avez pu tester et apprenez-en plus sur elles.",
      "recommendationTitle": "Recevez des recommandations\npersonalisées",
      "recommendationText": "Remplissez votre profil de dégustation renseignant vos types de vins favoris et nous vous enverrons des recommandations de nouvelles bouteilles à découvrir quotidiennement.",
      "friendsTitle": "Suivez ce que vos\namis dégustent",
      "friendsText": "Prenez des nouvelles de vos amis, voyez quels vins ils dégustent en ce moment et lesquels ils recommandent.",
      "rewardsTitle": "Recevez des récompenses en\nutilisant l'application",
      "rewardsText": "Recevez des badges sur votre profil en scannant des vins, en écrivant des avis et en utilisant l'application quotidiennement.",
      "ambassadorTitle": "PROGRAMME AMBASSADEUR",
      "ambassadorText": "Faites connaître l’aérateur Aveine Original à vos amis et contacts. Grâce à votre code, ils bénéficieront d’une réduction exclusive de 10 % sur l’achat d’un aérateur Aveine, et vous recevrez une commission de 42 €. \n \n Quelque soit votre domaine d’activité, vous pouvez devenir un Ambassadeur Aveine. Il vous suffit d’avoir une structure (indépendant, société) qui vous permette d’émettre des factures.",
      "curiousAboutAmbassadorProgram":"Vous ne connaissez pas le programme Ambassadeur Aveine ?",
      "learnMore": "En savoir plus",
      "aboutAveineTitle": "A propos d'Aveine",
      "aboutAveineText": "Aveine est le premier aérateur de vin digital. Il vous permet d’aérer instantanément vos vins à la perfection, pour une expérience de dégustation optimale. Déjà adopté par les professionnels, il séduit aussi tous les passionnés de vin exigeants. À vous de le découvrir !"
    },
    "title": {
      "aveine": "Aveine",
      "myAmbassadorsList": "Mes Ambassadeurs",
      "language": "Langue:",
      "taxIncluded": "Hors Taxe",
      "contactMessage": "En cas de problèmes techniques ou questions sur le Programme Ambassadeur, merci de nous contacter à l'adresse suivante : ",
      "becomeAmbassador": "Devenir Ambassadeur",
      "becomeAmbassadorMessage": "Veuillez renseigner votre adresse mail, vous recevrez ensuite une invitation pour devenir ambassadeur",
      "email": "Adresse mail",
      "invitationSent": "Invitation envoyée",
      "wineFormToolTip": "Les vins soumis devront être validés avant d'apparaître dans notre base de données",
      "tosSignature": "Mise à jour des conditions générales d'utilisation",
      "editProfile": "Edition du Profil",
      "declareRevenue": "Déclarer mes revenues",
      "declare": "Je déclare",
      "websitePromo": "Les Dernières <br> nouveautés d'Aveine",
      "accessWebsite": "Accèder au site",
      "newTosAvailable": "Les CGV ont été mis à jour",
      "agreeTos": "J'accepte les conditions générales de vente",
      "submit": "Valider",
      "invitationEmail": "Email qui recevera l'invitation",
      "send": "Envoyer",
      "profileSaved": "Profil sauvegardé",
      "noScannedWine": "Scannez des vins via l'application mobile et retrouvez les ici",
      "cookieLaw": {
        "message": "Ce site web utilise des cookies pour vous garantir la meilleure expérience possible sur notre site.",
        "privacyPolicy": "Politique de confidentialité",
        "accept": "J'accepte",
        "refuse": "Je refuse"
      },
      "menu": {
        "navigation": "Navigation",
        "home": "Accueil",
        "ambassadorDashboard": "Tableau de bord",
        "editMyInformation": "Editer mes informations",
        "invoices": "Mes Factures",
        "mySales": "Mes ventes",
        "contactUs": "Contactez-nous"
      },
      "page": {
        "home": "Tableau de bord",
        "editMyInformation": "Editer mes informations",
        "myInvoices": "Mes Factures",
        "mySales": "Mes ventes",
        "createInvoice": "Emettre une facture",
        "declaredSales": "Ventes déclarées",
        "userList": "Liste d'utilisateur",
      }
    },
    "profile": {
      "profile": "Profil",
      "editProfile": "Edition du Profil",
      "editTastingProfile": "Edition Profil Dégustation",
      "followings": "Abonnements",
      "followers": "Abonnés",
      "name": "Nom utilisateur",
      "description": "Description",
      "language": {
        "en": "English",
        "fr": "Français"
      },
      "button": {
        "deleteImage": "Retirer l'avater",
        "submit": "Valider",
        "uploadFile": "Choisir un fichier",
        "cancel": "Annuler",
        "showcase": "Mettre en avant"
      }
    },
    "dashboard": {
      "submitAmbassadorInvoice": "Soumettre une facture Ambassadeur",
      "submitSuperAmbassadorInvoice": "Soumettre une facture Super Ambassadeur",
      "ambassadorInvoices": "Facture Ambassadeur en cours de validation",
      "superAmbassadorInvoices": "Facture Super Ambassadeur en cours de validation",
      "domainsWines": "Mes vins",
      "ambassadorSales": "Total des ventes",
      "sales": "ventes",
      "superAmbassadorSales": "Ventes de mes Ambassadeurs",
      "seeMore": "Voir plus"
    },
    "invoices": {
      "ambassadorInvoices": "Factures ambassadeur",
      "superAmbassadorInvoices": "Factures super ambassadeur",
      "totalDeclared": "Total des revenues déclarés"
    },
    "sales": {
      "title": "Mes ventes",
      "discountCode": "Code promo",
      "currency": "Devise",
      "unlockedMoney": "Montant déclarable disponible",
      "lockedMoney": "Montant en cours",
      "unlockedAt": "Déclarable à partir du "
    },
    "onBoarding": {
      "start": "Commencer",
      "next": "Suivant",
      "back": "Précédent",
      "skip": "Ignorer",
      "submit": "Envoyer",
      "introduction": "Introduction",
      "welcome": "Bienvenue",
      "introText": "Vous allez être guidé étape par étape pour la création de votre nouveau statut Ambassadeur. <br><br> <b>Il est nécessaire d’avoir un statut professionnel pour intégrer le club Ambassadeur.</b>",
      "userNotLoggedIn": "Pour commencer veuillez vous identifier",
      "createProAccount": "Créer un compte Pro Aveine",
      "searchDomain": "Rechercher votre domaine",
      "selectDomainPrompt": "Veuillez rechercher votre domaine (Si vous ne le trouvez pas vous pouvez ignorer cette étape pour l'ajouter)",
      "userDomainField": "Domain",
      "domainValidation": "Le domaine que vous recherchez est introuvable",
      "domainAlreadyRegistered": "Ce domaine est déjà inscrit",
      "login": "Identification",
      "tos": "Conditions Générales d'Utilisation",
      "agreeTos": "J'accepte les conditions générales d'utilisation",
      "accountInfo": "Informations compte",
      "complementaryInfo": "Informations complémentaires",
      "payment": "Paiement",
      "checkout": "Commander votre kit",
      "orderButton": "Commander",
      "startRegistration": "Commencer mon inscription",
      "signIn": "S'inscrire",
      "logIn": "Se connecter",
      "storeSelection": "Pour devenir Ambassadeur, vous devez disposer d’un kit ambassadeur comprenant un aérateur, un code promo personnalisé et des supports de communication.",
      "willShipTo": "Le kit est à expédier : ",
      "france": "En France",
      "europe": "En Europe",
      "northAmerica": "En Amérique du Nord",
      "international": "Autre",
      "alreadyHaveAPurchaseCode": "Une fois le paiement effectué, vous allez recevoir un code d'achat.\nSi vous l'avez déjà, merci de le renseigner ci-dessous pour continuer l'inscription.",
      "purchaseCode": "Entrez un code d'achat",
      "purchaseCodeToolTip": "Le code d'achat vous est donné sur la page de confirmation de votre commande.",
      "accountCreationInfo": "Pour finaliser la création de votre compte, veuillez renseigner une adresse mail valide et un mot de passe. Vous utiliserez ensuite les identifiants créés pour vous connecter à l'espace Pro Aveine :",
      "personalInfoText": "Pour finir, veuillez remplir les informations en rapport à votre activité.\nCes informations seront publiques et apparaîtront sur les différentes applications Aveine.\n (*Seuls le nom, la socièté et l'adresse sont obligatoires)",
      "finalization": "Finalisation",
      "signInSuccess": "Bienvenue !\nVous êtes désormais Ambassadeur Aveine",
      "proceedWithLogin": "Veuillez maintenant vous connecter en cliquant sur le bouton ci-dessous",
      "backToIndex": "Retourner à mon tableau de bord",
    },
    "errors": {
      "error": "Erreur",
      "inputRequired": "Ce champ est requis",
      "cantRetrieveSelf": "Impossible de trouver le compte ambassadeur associé",
      "errorPassword": "Le mot de passe ne correspond pas",
      "emailValidation": "L'email donné est invalide",
      "passwordValidation": "Le mot de passe ne remplit pas les conditions",
      "registrationError": "Une erreur innatendue s'est produite, veuillez rafraichir la page.\nSi le problème persiste veuillez nous contacter",
      "roleAlreadyAssigned": "Vous possèdez déjà le status d'ambassadeur",
      "invitationAlreadyConsumed": "L'invitation que vous essayez d'ouvrir a déjà été utilisée ou a expirée",
      "noPurchaseCode": "Veuillez entrer un code d'achat pour continuer",
      "notProError": "Votre compte n'a pas les droits d'accéder aux fonctionalités de l'Espace Pro Aveine",
      "pageNotFound": "Page introuvable",
      "unknownError": "Erreur inconnue"
    }
  }
}