import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/login.jpg'
import _imports_1 from '@/assets/logo-aveine-white.png'


const _hoisted_1 = { class: "login login-header d-flex flex-row justify-center" }
const _hoisted_2 = { class: "d-flex flex-column pl-xl-10 pl-lg-8 header-banner justify-xl-center justify-lg-center" }
const _hoisted_3 = { class: "mt-6" }
const _hoisted_4 = { class: "font-weight-regular mb-6" }
const _hoisted_5 = { class: "mt-xl-8 mt-lg-6 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_AveineLogin = _resolveComponent("AveineLogin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$vuetify.display.name == 'xl' || _ctx.$vuetify.display.name == 'lg')
      ? (_openBlock(), _createBlock(_component_v_img, {
          key: 0,
          class: "banner-img",
          src: _imports_0,
          alt: ""
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_list_item, { class: "d-flex pl-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            modelValue: _ctx.$i18n.locale,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
            items: ['FR', 'EN'],
            "single-line": ""
          }, null, 8, ["modelValue"])
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_v_img, {
        alt: "Aveine",
        src: _imports_1,
        width: "360",
        "max-height": "50"
      }),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("aveine-space.homepage.ambassadorTitle")), 1),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("aveine-space.homepage.ambassadorText")), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_btn, { to: { name: 'Registration'} }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("aveine-space.onBoarding.startRegistration")), 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_AveineLogin)
      ])
    ])
  ]))
}