import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const aveineTheme = {
  dark: false,
  colors: {
    background: '#fdfaf7',
    surface: '#FFFFFF',
    primary: "#1d1f2b",
    secondary: "#923834",
    beige: "#efeae4",
    misc: "#3e3e3e",
    error: '#6E2828',
    info: '#F0E6DD',
    success: "#194E4B",
    warning: '#FB8C00',
  }
}

export default createVuetify({
  ssr: true,
  components,
  directives,
  theme: {
    defaultTheme: 'aveineTheme',
    themes: {
      aveineTheme
    }
  }
});
