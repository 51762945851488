import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n';
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)

app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places'
  },
  installComponents: true
})


app.use(router)
app.use(vuetify)
app.use(i18n)

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $auth: any;
    $vtools: any;
    $vtoast: any;
  }
}

app.config.globalProperties.$auth = {isAuthenticated: false, isLoading: true}
app.config.globalProperties.$vtools = { toast: false }


app.mount('#app')