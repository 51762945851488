<template>
  <v-container class="wrapper d-flex justify-center align-center py-16">
    <h3 class="text-center">{{$t("aveine-space.errors.notProError")}} <br> {{$t('aveine-space.title.menu.contactUs')}} <a href="mailto:ambassadeurs@aveine.paris">ambassadeurs@aveine.paris</a></h3>
  </v-container>
</template>

<script>

  export default {
    name: 'NotRegistered',
  }
</script>
