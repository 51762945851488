<template>
  <div class="d-flex flex-xl-row flex-lg-row flex-column home my-5">
    <div v-if="$vuetify.display.name == 'xl' || $vuetify.display.name == 'lg'" class="d-flex flex-column justify-xl-center mx-2 align-xl-center">
      <div class="column-1">
        <v-card class="d-flex pa-6 px-xl-14 px-lg-12 rounded-lg chart-container">
          <h2>{{$t("aveine-space.title.page.mySales")}}</h2>
          <AveineSaleCharts class="pt-8 pl-6 component" :dashboardActions="true" :ambassadorId="$auth.ambassador.id" :discountCode="$auth.ambassador.promotion_code" :selectedCurrency="selectedCurrency" />
        </v-card>
      </div>
    </div>

    <div class="d-flex flex-column justify-xl-center mx-2 align-center">
      <div v-if="$vuetify.display.name == 'xl' || $vuetify.display.name == 'lg'" class="header mx-2">
        <div class="d-flex justify-center">
          <v-card class="py-6 px-14 rounded-lg discount-code-card" v-if="$auth.ambassador">
            <v-col class="discount-code d-flex flex-column align-center mt-4">
              <p class="ma-1 text-light">{{$t("aveine-space.sales.discountCode")}}</p> <h2 class="ma-1">{{$auth.ambassador.promotion_code}}</h2>
            </v-col>
          </v-card>
        </div>
      </div>

      <div class="d-flex mx-2">
        <v-card class="sale-details rounded-lg text-left align-start">
          <h2 class="mb-4 ml-6">
            {{$t("aveine-space.dashboard.ambassadorSales")}} 
          </h2>
          <AveineSaleDetails class="component" :selectedCurrency="selectedCurrency" :monthSalesNumber="monthSalesNumber" :yearSalesNumber="yearSalesNumber" :totalSalesNumber="totalSalesNumber" :totalIncome="totalIncome" :pendingIncome="pendingIncome" :adminMode="true"/>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AveineSaleDetails from 'aveine-sale/src/components/AveineSaleDetails.vue'
import AveineSaleCharts from 'aveine-sale/src/components/AveineSaleCharts.vue'
import { Order, OrderType} from "aveine-toolbox/src/store/models/Order"
import errorHandler  from 'aveine-toolbox/src/store/errorHandler'
export default defineComponent({
  data () {
    return {
      orders: [] as OrderType[],
			/**
			 * Represent the different currencies
			 *
			 * @type {Object[]}
			 */
			currencies: [
				{
					name: 'Euros',
					code: 'EUR',
				},
				{
					name: 'Dollars',
					code: 'USD',
				}
      ],
      /**
       * The selected currency
       * 
       * @type {String}
       */
      selectedCurrency: null as string | null,
      /**
       * Flag to know if page data has been loaded
       * 
       * @type {boolean}
       */
      dataLoaded: false as boolean,
      /**
       * Store monthly sale number by product
       * 
       * @type {Object}
       */
      monthSalesNumber: Object as any,
      /**
       * Store yearly sale number by product
       * 
       * @type {Object}
       */
      yearSalesNumber: Object as any,
      /**
       * Store total sale number by product
       * 
       * @type {Object}
       */
      totalSalesNumber: Object as any,
      /**
       * Store the pending income the current user has grossed
       * 
       * @type {Object}
       */
      pendingIncome: Object as any,
      /**
       * Store the total income the current user has grossed
       * 
       * @type {Object}
       */
      totalIncome: Object as any,
      /**
       * Store the invoicing information of the current user
       * 
       * @type {Object}
       */
      invoiceSummary: Object as any,
      /**
       * Check if device is ios
       * This check is here because some iphones and ipad don't really like the chart library used for 
       * displaying graphs so i just hide them to prevent crashes
       * (also it's impossible to display the chart correctly on a tiny screen it's not a big problem it's
       * supposed to be read on a big screen)
       */
      deviceIsIos: false as boolean
    }
  },
  name: 'SalesRead',
  components: {
    AveineSaleDetails,
    AveineSaleCharts
  },
  async created() {
    try {
      this.selectedCurrency = this.currencies[0].code;
      
      let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('.')[0]+"Z";
      let endDate =  new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().split('.')[0]+"Z";

      let fetchMonthSummary = await Order.api("GET", `orders/ambassadors/${this.$route.params.id}/data/${startDate}/${endDate}`)

      this.monthSalesNumber = {aveineAmount: fetchMonthSummary["total-aveine"], essentielAmount: fetchMonthSummary["total-estl"]}
      
      let currentYear = new Date().getFullYear();

      let fetchYearSummary = await Order.api("GET", `orders/ambassadors/${this.$route.params.id}/data/${currentYear}`)

      this.yearSalesNumber = {aveineAmount: fetchYearSummary["total-aveine"], essentielAmount: fetchYearSummary["total-estl"]}

      let fetchTotalSummary = await Order.api("GET", `orders/ambassadors/${this.$route.params.id}/summary`)
      this.invoiceSummary = fetchTotalSummary.meta;

      this.totalSalesNumber = {aveineAmount: this.invoiceSummary["total-aveine-amount"], essentielAmount: this.invoiceSummary["total-estl-amount"]}
      this.totalIncome = { 
        USD: parseInt(this.invoiceSummary["amount-paid-dollar"]),
        EUR: parseInt(this.invoiceSummary["amount-paid-euro"])
      }
      this.pendingIncome = {
        USD: parseInt(this.invoiceSummary["amount-locked-dollar"]) + parseInt(this.invoiceSummary["amount-unlocked-dollar"]) + parseInt(this.invoiceSummary["amount-pending-dollar"]),
        EUR: parseInt(this.invoiceSummary["amount-locked-euro"]) + parseInt(this.invoiceSummary["amount-unlocked-euro"]) + parseInt(this.invoiceSummary["amount-pending-euro"]),
      }

      const userAgent = navigator.userAgent;

      if (/iPad|iPhone/.test(userAgent)) {
        this.deviceIsIos = true
      }

      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  }
})
</script>

<style lang="scss" scoped>
  div.home {
    .v-card {
      background-color: rgb(var(--v-theme-beige));
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 52vw;
      margin: 3em;
      height: 500px;
      .v-avatar {
        border-radius: 50%;
        padding: 20px;
        background-color: white;
      }
      div.discount-code {
        margin-bottom: 2vh;
        h2 {
          background-color: rgb(var(--v-theme-primary));
          font-family: 'Montserrat', sans-serif !important;
          font-weight: bold;
          padding: 5px 1.8em;
          border-radius: 10px;
          color: white;
          font-size: 1.3em;
        }
      }
      h2 {
        font-family: 'ivypresto-display', sans-serif;
        font-weight: normal;
        text-align: left;
        font-size: 30px;
        line-height: 1.3em;
      }
    }
    .discount-code-card {
      max-height: 150px !important;
    }
    .chart-container {
      background-color: white;
      text-align: start;
      justify-content: start;
      align-items: start;
      min-width: 50vw;
    }
    .promotional-banner {
      background-image: url('../../../assets/promo_banner.png');
      background: cover;
      min-width: 40vw;
      height: 80%;
      align-items: start !important;
      h2 {
        color: white;
      }
    }
    .sale-details {
        background-color: rgb(var(--v-theme-primary)) !important;
        border-radius: 0 20px 20px 20px;
        color: white !important;
        min-width: 350px;
        hr {
          color: white !important;
          opacity: 100 !important;
        }
    }
    @media (min-width: 1280px) and (max-width: 1600px) {
      .chart-container {
        width: 50vw;
        margin-right: 0;
        margin-left: 10px;
      }
      .discount-code-card {
        margin-left: 0;
      }
      .sale-details {
        width: 300px;
        margin-left: 0;
      }
    }
    @media (max-width: 700px){
      .column-1 {
        display: flex;
        justify-content: center;
        width: 98vw;
      }
      .column-2 {
        width: 98vw;
      }
      .v-card {
        margin: 10px;
        max-width: 100%;
      }
    }
  }


</style>

