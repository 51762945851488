<template>
  <div class="d-flex flex-xl-row flex-lg-row flex-column-reverse home my-5">
    <div class="d-flex flex-column justify-center align-center">
      <div class="d-flex justify-center">
        <v-card class="d-flex flex-column pa-6 px-xl-14 px-lg-12 rounded-lg chart-container">
          <h2>{{$t("aveine-space.title.page.myInvoices")}}</h2>
          <AveineInvoiceManagement  v-if="invoices.length" :APIbaseURL="APIbaseURL" :invoices="invoices" />
          <div class="d-flex justify-xl-center align-xl-center justify-lg-center align-lg-center flex-column no-invoice ma-10" v-else>
            <div class="d-flex justify-xl-center align-xl-center justify-lg-center align-lg-center">
              <v-icon class="ma-8" color="primary" size="128">mdi-close-octagon-outline</v-icon>
            </div>
            <span>{{$t("aveine-invoice.title.noInvoice")}}</span>
          </div>
          <div class="text-center d-flex justify-center align-center">
            <v-pagination
              v-model="currentPage"
              :length="paginationLength"
              :total-visible="paginationLength < 7 ? paginationLength : 7"
            ></v-pagination>
          </div>
        </v-card>
      </div>
    </div>

    <div class="d-flex flex-column justify-center align-center">
      <div class="header">
        <div class="d-flex justify-center">
          <v-card class="py-6 px-xl-14 px-lg-12 px-4 rounded-lg discount-code-card" v-if="$auth.ambassador">
              <h4>{{$t("aveine-space.sales.unlockedMoney")}}</h4>
              <span v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']"><h1>{{invoiceSummary["amount-unlocked-euro"] ? invoiceSummary["amount-unlocked-euro"] + " EUR" : invoiceSummary["amount-unlocked-dollar"] + " USD"}}</h1><i>({{$t("aveine-space.title.taxIncluded")}})</i></span>
              <h1 v-else>0 EUR</h1>
              <v-btn
              v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']"
              class="my-5" rounded-lg
              color="primary"
              variant="flat"
              :to="{ name:'InvoiceCreate', params: {id: $auth.ambassador.id} }"
            >
              {{$t("aveine-space.title.declare")}}
            </v-btn>
          </v-card>
        </div>
      </div>

    <div class="d-flex flex-column justify-center align-center">
      <div class="header">
        <div class="d-flex justify-center">
            <v-card class="py-6 px-xl-14 px-lg-12 px-4 rounded-lg discount-code-card" v-if="$auth.ambassador">
              <span>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <div
                      class="mb-2 d-flex align-center"
                      v-bind="props"
                    >
                      <h4>{{$t("aveine-space.sales.lockedMoney")}}</h4> 
                      <v-icon size="small">
                        mdi-help
                      </v-icon>
                    </div>
                  </template>
                  <span>{{$t("aveine-invoice.tooltip.moneyAvailable")}}</span> <br>
                  <span>{{$t("aveine-invoice.tooltip.moneyLocked")}}</span>
                </v-tooltip>
              </span> 
              <span v-if="invoiceSummary['amount-locked-euro'] || invoiceSummary['amount-locked-dollar']"> <h1>{{invoiceSummary["amount-locked-euro"] ? invoiceSummary["amount-locked-euro"] + " EUR" : invoiceSummary["amount-locked-dollar"] + " USD"}}</h1><i>({{$t("aveine-space.title.taxIncluded")}})</i></span>
              <i v-if="invoiceSummary['amount-locked-euro'] || invoiceSummary['amount-locked-dollar']">({{$t("aveine-space.sales.unlockedAt")}} {{new Date(invoiceSummary["will-unlock-at"]).toLocaleDateString("fr-FR")}})</i>
              <span v-else><h1>0 EUR</h1></span> 
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- <div v-if="dataLoaded" class="sheet-container">
      <div class="d-flex flex-xl-row flex-lg-row flex-column justify-start mt-4 mr-xl-16 mr-lg-12">
        <v-select
          class="ma-6"
          v-model="payedFilter"
          :items="[{text:$t(`aveine-invoice.title.all`), value:null},
                    {text:$t(`aveine-invoice.title.payed`), value:true},
                    {text:$t(`aveine-invoice.title.notPayed`), value:false}]"
          :label="$t('aveine-invoice.title.paymentFilter')"
        ></v-select>
        <v-select
          class="ma-6"
          v-model="sortInvoice"
          :items="[{text:$t(`aveine-invoice.title.desc`), value:'desc'},
                  {text:$t(`aveine-invoice.title.asc`), value:'asc'}]"
          :label="$t('aveine-invoice.title.sort')"
        ></v-select>
    </div> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AveineInvoiceManagement  from 'aveine-invoice/src/components/AveineInvoiceManagement.vue';
import { Invoice, InvoiceType }  from 'aveine-toolbox/src/store/models/Invoice';
import { Order } from 'aveine-toolbox/src/store/models/Order'
import errorHandler from 'aveine-toolbox/src/store/errorHandler'
export default defineComponent({
  data () {
    return {
      invoices: [] as InvoiceType[],
      invoiceSummary: {} as any,
      sortInvoice: "desc" as string,
      payedFilter: null as boolean | null,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      paginationLength: 0 as number,
      dataLoaded: false as boolean
    }
  },
  name: 'InvoiceSheet',
  components: {
    AveineInvoiceManagement
  },

  watch: {
    payedFilter () {
      this.loadInvoices();
    },
    sortInvoice () {
      this.loadInvoices()
    },
    async currentPage (val) {
      this.dataLoaded= false;
      this.$router.push({ path: '/authenticated/pro/invoices', query: { page: val }});
      this.loadInvoices()
    },
  },
  
  async mounted() {
    if (this.$auth.ambassador && this.$auth.ambassador.id) {
      this.loadInvoices();
      let fetchTotalSummary = await Order.api("GET", `orders/ambassadors/${this.$auth.ambassador.id}/summary`)
      this.invoiceSummary = fetchTotalSummary.meta;
    } else {
      this.$router.replace("/")
    }
  },
  methods: {
    async loadInvoices () {
      try {
        this.invoices = [];
        this.dataLoaded = false;
        
        let params: any =  
        {
          "include": 'ambassador.user',
          "page[number]": this.$route.query.page || 1,
          "page[size]": 40,
        };

        if (this.payedFilter == true) 
          params[`filter[paid-at][NE]`] = "null"
        else if (this.payedFilter == false)
          params[`filter[paid-at]`] = "null"
        else 
          params[`filter[paid-at]`] = null

        if (this.sortInvoice == "desc")
          params[`sort`] = "-created-at"
        else if  (this.sortInvoice == "asc")
          params[`sort`] = "created-at"


        let invoices = await Invoice.api("GET", `ambassadors/${this.$route.params.id}/invoices`, null, params);

        invoices.map((invoice) => {
          invoice.ambassador = this.$auth.ambassador
        })
        this.invoices = invoices
        this.paginationLength = invoices.meta.last
        this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
  div.home {
    .v-card {
      background-color: rgb(var(--v-theme-beige));
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 52vw;
      margin: 3em;
      height: 500px;
      .v-avatar {
        border-radius: 50%;
        padding: 20px;
        background-color: white;
      }
      div.discount-code {
        margin-bottom: 2vh;
        h2 {
          font-family: 'Montserrat', sans-serif !important;
          font-weight: bold;
          padding: 5px 1.8em;
          border-radius: 10px;
          font-size: 1.3em;
        }
      }
      h2 {
        font-family: 'ivypresto-display', sans-serif;
        font-weight: normal;
        text-align: left;
        font-size: 30px;
        line-height: 1.3em;
      }
      h1 {
        font-family: 'ivypresto-display', sans-serif;
        font-weight: normal;
        text-align: left;
        font-size: 42px;
        line-height: 1.3em;
      }
    }
    .discount-code-card {
      max-height: 200px !important;
      width: 400px;
      text-align: center;
    }
    .chart-container  {
      background-color: white;
      text-align: start;
      justify-content: start;
      align-items: start;
      min-width: 50vw;

    }

    #aveine-invoice-list {
      overflow-y: scroll;
      //Scrollbar style for Firefox
      scrollbar-color: rgb(var(--v-theme-primary)) rgb(var(--v-theme-background));
      scrollbar-width: thin;
    }

    //Scrollbar style for webkit browsers
    #aveine-invoice-list::-webkit-scrollbar {
      width: 8px;
    }
    #aveine-invoice-list::-webkit-scrollbar-track {
      margin: 20px;
      background: rgb(var(--v-theme-background));
      border-radius: 10px;
    }
    #aveine-invoice-list::-webkit-scrollbar-thumb {
      background: rgb(var(--v-theme-primary));
      border-radius: 10px;
    } 
    div.no-invoice {
      justify-content: center !important;
      width: 100%;
    }

    .sale-details {
        border-radius: 0 20px 20px 20px;
        min-width: 350px;
        hr {
          color: white !important;
          opacity: 100 !important;
        }
    }

    @media (min-width: 1280px) and (max-width: 1600px) {
      .chart-container {
        width: 50vw;
        margin-right: 0;
        margin-left: 10px;
      }
      .discount-code-card {
        margin-left: 20px;
      }
      .sale-details {
        width: 300px;
        margin-left: 20px;
      }
    }

    @media (max-width: 700px){
      .column-1 {
        display: flex;
        justify-content: center;
        width: 98vw;
      }
      .column-2 {
        width: 98vw;
      }
      .v-card {
        margin: 10px;
        max-width: 100%;
        width: 98vw;
      }
      .discount-code-card {
        width: 98vw;
      }
    }
  }


</style>